import { API } from './services/typings';

export default function access(initialState: { user?: API.User | undefined, settings?: API.ConfigEntry[] }) {
  const { user } = initialState || {};
  const onlyAdminAccessLogsValue = initialState?.settings?.find((entry) => entry.key === 'ONLY_ADMIN_ACCESS_LOGS')?.value;
  const onlyAdminAccessLogs = onlyAdminAccessLogsValue === 'Y' || onlyAdminAccessLogsValue === 'TRUE';
  return {
    canAdmin: user && user.is_admin == true,
    canViewAudit: user && (user.is_admin == true || !onlyAdminAccessLogs),
  };
}
